
::v-deep {
    .max-width-0 {
        max-width: 0;
    }
    .line-height-1-5 {
        line-height: 1.5;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__mobile-row__cell {
        max-width: calc(100vw - 12px - 16px - 80px - 16px - 12px);
    }
}
