
::v-deep .text-align-right-field {
    & > .v-input__control > .v-input__slot > .v-text-field__slot > input {
        text-align: right !important;
    }
}
::v-deep .amount-field {
    & > .v-input__control > .v-input__slot {
        padding-right: 0 !important;
    }
    & > .v-input__control > .v-input__slot > .v-input__append-inner {
        margin: 1px 0 0;
    }
    & > .v-input__control > .v-input__slot > .v-input__append-inner > .v-text-field--solo {
        width: 58px !important;

        .v-input__slot {
            padding-right: 4px;
        }
        .v-select__selections {
            justify-content: center;

            > .v-select__selection {
                margin-right: 0;
            }

            > input {
                display: none;
            }
        }

        .v-input__control,
        .v-input__slot {
            background-color: transparent;
        }
        .v-input__append-inner {
            margin-top: 0;
            padding-left: 0;
        }
    }
}

::v-deep .v-text-field__details {
    margin-bottom: 0 !important;
}

::v-deep .w-100-px {
    width: 100px;
}
