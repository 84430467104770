
::v-deep {
    .echarts {
        width: 100%;
        height: 100%;
    }
    .max-width-0 {
        max-width: 0;
    }
}
